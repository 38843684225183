import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import classnames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { fonts, colors } from '../constants';

const DescriptionList = styled.dl`
  & > dt {
    color: ${(props) => props.light && '#fff'};
    font-family: ${fonts.heading};
    font-size: 1.5rem;
    text-transform: none;
  }
`;

const UnorderedList = styled.ul`
  & > li {
    color: ${(props) => props.light && '#fff'};
  }
`;

const IconList = styled.div``;

function ContactInformation({ light }) {
  const { site } = useStaticQuery(SITE_QUERY);
  const { siteMetadata } = site;
  const className = classnames('uk-description-list', light && 'uk-light');

  return (
    <React.Fragment>
      <DescriptionList className={className} light={light}>
        <dt>Email</dt>
        <dd>
          <OutboundLink
            className="uk-link-text"
            href={`mailto:${siteMetadata.contactInfo.email}`}
          >
            {siteMetadata.contactInfo.email}
          </OutboundLink>
        </dd>
        <dt>Dirección</dt>
        <dd>
          <UnorderedList className="uk-list" light={light}>
            <li>{siteMetadata.addressInfo.addressLine}</li>
            <li className="uk-margin-remove-top">
              {`${siteMetadata.addressInfo.zip} ${siteMetadata.addressInfo.location}`}
            </li>
          </UnorderedList>
        </dd>
      </DescriptionList>

      <IconList>
        <span className="uk-margin-right" uk-lightbox="">
          <a
            href={siteMetadata.social.googleMaps.href}
            data-type="iframe"
            aria-label="Nuestra ubicación en Google Maps"
          >
            <FontAwesomeIcon
              icon={faMapMarkedAlt}
              size="2x"
              color={light ? '#fff' : undefined}
            />
          </a>
        </span>
        <span className="uk-margin-right">
          <OutboundLink
            href={siteMetadata.social.facebook}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Nuestra perfil de Facebook"
          >
            <FontAwesomeIcon
              icon={faFacebookSquare}
              size="2x"
              color={light ? '#fff' : colors.facebook}
            />
          </OutboundLink>
        </span>
        <span>
          <OutboundLink
            href={siteMetadata.social.instagram}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Nuestro perfil de Instagram"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              size="2x"
              color={light ? '#fff' : colors.instagram}
            />
          </OutboundLink>
        </span>
      </IconList>
    </React.Fragment>
  );
}

const SITE_QUERY = graphql`
  query {
    site {
      siteMetadata {
        generalInfo {
          name
          initYear
        }
        addressInfo {
          addressLine
          zip
          location
        }
        contactInfo {
          email
        }
        social {
          googleMaps {
            href
          }
          facebook
          instagram
        }
      }
    }
  }
`;

ContactInformation.defaultProps = {
  light: false,
};

ContactInformation.propTypes = {
  light: PropTypes.bool,
};

export default ContactInformation;
