import { useState } from 'react';

function useForm(initForm) {
  const [form, setForm] = useState(initForm);
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return [form, handleChange];
}

export default useForm;
