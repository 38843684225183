import React, { useReducer, useCallback } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { post } from 'axios';
import qs from 'qs';

import useForm from '../../hooks/useForm';

const Label = styled.label`
  color: transparent;
  font-size: 0;
`;

const formRequestReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        error: !action.payload,
        response: action.payload,
      };
    case 'FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
        response: false,
      };
    default:
      throw new Error();
  }
};

function ContactForm({ light }) {
  const [form, handleChange] = useForm({
    name: '',
    email: '',
    tel: '',
    message: '',
  });

  const [requestState, requestDispatch] = useReducer(formRequestReducer, {
    loading: false,
    error: false,
    response: false,
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      requestDispatch({ type: 'INIT' });
      post(
        '/',
        qs.stringify({
          'form-name': 'contact',
          ...form,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        }
      )
        .then((response) => {
          requestDispatch({
            type: 'SUCCESS',
            payload: response.status === 200,
          });
        })
        .catch(() => {
          requestDispatch({
            type: 'FAILURE',
          });
        });
    },
    [form, requestDispatch]
  );

  const { loading, response, error } = requestState;

  const buttonClassName = classnames(
    'uk-button',
    light ? 'uk-button-secondary' : 'uk-button-default'
  );

  const alertClassName = classnames(
    'uk-alert',
    response && !error ? 'uk-alert-success' : 'uk-alert-danger'
  );

  const alertMessage =
    response && !error
      ? '¡Muchas gracias! Nos pondremos en contacto a la brevedad'
      : '¡Ocurrió un error al procesar su formulario!';

  return (
    <React.Fragment>
      <form
        name="contact"
        data-netlify="true"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input
          name="form-name"
          value="contact"
          type="hidden"
          aria-label="netlify form hidden input"
        />

        <fieldset className="uk-fieldset">
          <div className="uk-margin-bottom">
            <Label htmlFor="name">Nombre</Label>
            <input
              id="name"
              name="name"
              type="text"
              className="uk-input"
              aria-label="Nombre"
              placeholder="Nombre"
              value={form.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="uk-margin-bottom">
            <Label htmlFor="email">Email</Label>
            <input
              id="email"
              name="email"
              type="email"
              className="uk-input"
              aria-label="Email"
              placeholder="Email"
              value={form.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="uk-margin-bottom">
            <Label htmlFor="tel">Teléfono</Label>
            <input
              id="tel"
              name="tel"
              type="tel"
              className="uk-input"
              aria-label="Telefono"
              placeholder="Teléfono"
              value={form.tel}
              onChange={handleChange}
              required
            />
          </div>

          <div className="uk-margin-bottom">
            <Label htmlFor="message">Mensaje</Label>
            <textarea
              id="message"
              name="message"
              className="uk-textarea"
              rows="5"
              aria-label="Mensaje"
              placeholder="Su mensaje"
              value={form.message}
              onChange={handleChange}
              style={{ resize: 'none' }}
            />
          </div>

          <button className={buttonClassName} type="submit" disabled={response}>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Enviar'}
          </button>
        </fieldset>
      </form>
      {(response || error) && (
        <div className={alertClassName} uk-alert="">
          <p>{alertMessage}</p>
        </div>
      )}
    </React.Fragment>
  );
}

export default ContactForm;
