import React from 'react';
import PropTypes from 'prop-types';

import { ContactForm, ContactInformation } from '../components/Contact';

function ContactUs({ light }) {
  return (
    <div uk-grid="" className="uk-grid uk-grid-medium uk-child-width-1-2@s">
      <div>
        <ContactForm light={light} />
      </div>

      <div>
        <ContactInformation light={light} />
      </div>
    </div>
  );
}

ContactUs.defaultProps = {
  light: false,
};

ContactUs.propTypes = {
  light: PropTypes.bool,
};

export default ContactUs;
